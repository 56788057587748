<template>
  <my-popup
    v-if="isboundAttribute1Loaded
      && isboundAttribute2Loaded
      && attributeToNestLoaded
      && isIndividualAttributeLoaded"
    :anchor="clickedButtonElement"
    placement="insideTable"
    @clickoutside="handleModalClose"
  >
    <div class="popover modal-container">
      <h6 class="attributes-select-header">
        <l10n value="Bind attribute:" />
        {{ attributeBindingData.baseAttribute.name }}
      </h6>
      <div class="attributes-select-container" v-if="!attributeToNest && !individualAttribute">
        <p class="attributes-select-title">
          <l10n value="Bind to:" />
        </p>
        <div class="attributes-selects">
          <my-select
            placeholder="Select attribute to bind"
            :incomingOptions="attributeBindingData.attributes"
            :initialSelection="initialBoundAttribute1"
            @select-change="handleSelectChange($event, 'boundAttribute1')"
          />
          <my-select
            placeholder="Select attribute to bind"
            :incomingOptions="attributeBindingData.attributes"
            :initialSelection="initialBoundAttribute2"
            :disabled="boundAttribute1 === null"
            @select-change="handleSelectChange($event, 'boundAttribute2')"
          />
        </div>
      </div>
      <div class="attributes-select-container" v-if="!boundAttribute1 && !individualAttribute">
        <p class="attributes-select-title">
          <l10n value="Nest to:" />
        </p>
        <my-select
          placeholder="Select attribute to be nested to"
          :incomingOptions="attributeBindingData.attributes"
          :initialSelection="initialAttributeToNest"
          @select-change="handleSelectChange($event, 'attributeToNest')"
        />
      </div>
      <gp-check
        v-if="!boundAttribute1"
        :checked="individualAttribute"
        @change="handleIndividualAttributeChange"
      >
        <l10n value="Is individual attribute" />
      </gp-check>
      <div class="attributes-select-buttons">
        <button
          type="button"
          class="btn btn-sm btn-primary btn-apply"
          @click="handleApplyChanges"
        >
          <l10n value="Apply changes" />
        </button>
        <button
          v-if="isAttributeBinded"
          type="button"
          class="btn btn-sm btn-danger btn-delete"
          @click="handleDeleteBind"
        >
          <l10n value="Delete bind" />
        </button>
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          @click="handleClose"
        >
          <l10n value="Discard" />
        </button>
      </div>
    </div>
  </my-popup>
</template>

<script>
const MySelect = require('../my-select.vue').default;

module.exports = {
  components: {
    'my-select': MySelect,
  },

  props: {
    isBoundAttributesModalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    attributeBindingData: {
      type: Object,
      required: true,
    },
    clickedButtonElement: {
      type: [
        Object,
        HTMLElement,
      ],
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    filter2: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      attributesToBind: [],
      individualAttribute: null,
      boundAttribute1: null,
      boundAttribute2: null,
      attributeToNest: null,
      isboundAttribute1Loaded: false,
      isboundAttribute2Loaded: false,
      attributeToNestLoaded: false,
      isIndividualAttributeLoaded: false,
      isAttributeBinded: false,
    };
  },

  computed: {
    initialBoundAttribute1() {
      if (!this.boundAttribute1) {
        return null;
      }

      return [{ name: this.boundAttribute1, text: this.boundAttribute1 }];
    },
    initialBoundAttribute2() {
      if (!this.boundAttribute2) {
        return null;
      }

      return [{ name: this.boundAttribute2, text: this.boundAttribute2 }];
    },
    initialAttributeToNest() {
      if (!this.attributeToNest) {
        return null;
      }

      return [{ name: this.attributeToNest, text: this.attributeToNest }];
    },
    linkType() {
      if (this.individualAttribute) {
        return 'individual';
      }
      if (this.boundAttribute1 || this.boundAttribute2) {
        return 'hierarchy';
      }
      if (this.attributeToNest) {
        return 'nest';
      }
      return 'not selected';
    },
  },

  beforeMount() {
    this.getBoundAttributes();
    this.getAttributeToNest();
    this.getIndividualAttribute();
  },

  methods: {
    getBoundAttributes() {
      const query = `
        query{
          dataset {
            source(name: "PLM_attributes_bind_last") {
              report(
                name: "gp-table", 
                cores: 64, 
                cache: true, 
                stored: false, 
                filter2: "${this.generateFilterString()}", 
                vals: "bound_atr1, bound_atr2, bound_atr3", 
                cols: "bound_atr1, bound_atr2, bound_atr3", 
                sort: []) 
              {
                rows
              }
            }
          }
        }`;
      fetch('/graphql?__getBoundAttributes__', {
        method: 'POST',
        body: JSON.stringify({ query }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result?.data) {
            const { data: { dataset: { source: { report: { rows } } } } } = result;
            const boundAttributes = rows[0].map((attribute) => attribute);
            const baseAttribute = this.attributeBindingData.baseAttribute.name;

            boundAttributes
              .filter((attribute) => attribute !== baseAttribute)
              .forEach((attr, index) => {
                if (index === 0) {
                  this.boundAttribute1 = attr;
                } else if (index === 1) {
                  this.boundAttribute2 = attr;
                }
              });

            if (this.boundAttribute1 || this.boundAttribute2) {
              this.isAttributeBinded = true;
            }
          }
        })
        // TODO: make error handling for this case
        .catch(console.error)
        .finally(() => {
          this.isboundAttribute1Loaded = true;
          this.isboundAttribute2Loaded = true;
        });
    },

    getAttributeToNest() {
      const query = `
        query{
          dataset {
            source(name: "PLM_attributes_nest_last") {
              report(
                name: "gp-table", 
                cores: 64, 
                cache: true, 
                stored: false, 
                filter2: "${this.generateFilterString()}", 
                vals: "nested_into" 
                cols: "nested_into"  
                sort: []) 
              {
                rows
              }
            }
          }
        }`;
      fetch('/graphql?__getAttributesToNest__', {
        method: 'POST',
        body: JSON.stringify({ query }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result?.data) {
            const { data: { dataset: { source: { report: { rows } } } } } = result;
            const attributesToNest = rows.map((attribute) => attribute[0]);
            [this.attributeToNest] = attributesToNest;

            if (this.attributeToNest) {
              this.isAttributeBinded = true;
            }
          }
        })
        // TODO: make error handling for this case
        .catch(console.error)
        .finally(() => {
          this.attributeToNestLoaded = true;
        });
    },

    getIndividualAttribute() {
      const query = `
        query{
          dataset {
            source(name: "PLM_attributes_individual_last") {
              report(
                name: "gp-table", 
                cores: 64, 
                cache: true, 
                stored: false, 
                filter2: "${this.generateFilterString()}", 
                vals: "individ_atr" 
                cols: "individ_atr" 
                sort: []) 
              {
                rows
              }
            }
          }
        }`;
      fetch('/graphql?__getIndividualAttributes__', {
        method: 'POST',
        body: JSON.stringify({ query }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result?.data) {
            const { data: { dataset: { source: { report: { rows } } } } } = result;
            const individualAttributes = rows.map((attribute) => attribute[0]);
            [this.individualAttribute] = individualAttributes;

            if (this.individualAttribute) {
              this.isAttributeBinded = true;
            }
          }
        })
        // TODO: make error handling for this case
        .catch(console.error)
        .finally(() => {
          this.isIndividualAttributeLoaded = true;
        });
    },

    generateFilterString() {
      const filters = Object.entries(this.attributeBindingData.extraFilters).map((obj) => `${obj[0]} == '${obj[1]}'`);
      filters.push(`attribute == '${this.attributeBindingData.baseAttribute.name}'`);
      return filters.join(' && ');
    },

    handleModalClose() {
      this.$emit('bound-attributes-modal-close');
    },

    handleSelectChange(selectedOption, selectType) {
      this[selectType] = selectedOption;
    },

    handleIndividualAttributeChange(checked) {
      this.individualAttribute = checked;
    },

    handleApplyChanges() {
      const filters = this.attributeBindingData.extraFilters;
      const data = {
        year: filters.year,
        quarter: filters.quarter,
        brand: filters.brand,
        division: filters.division,
        target_group: filters.target_group,
        category: filters.category,
        category_plus: filters.category_plus,
        target_atr: this.attributeBindingData.baseAttribute.name,
        bound_atr1: this.boundAttribute1,
        bound_atr2: this.boundAttribute2,
        nest_atr: this.attributeToNest,
        individual_atr: this.individualAttribute,
        user: this.username,
      };

      const url = '/api/attribute-ap/setup-attributes';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      fetch(url, options)
        .then((response) => {
          if (!response.msg) {
            this.createNotification('Attribute successfully binded', 'success');
            this.handleClose();
          } else {
            this.createNotification('An error ocured while binding attrubute', 'error');
          }
        })
        // TODO: make error handling for this case
        .catch((error) => console.error(error.text));
    },

    handleDeleteBind() {
      const filters = this.attributeBindingData.extraFilters;
      const data = {
        year: filters.year,
        quarter: filters.quarter,
        brand: filters.brand,
        division: filters.division,
        target_group: filters.target_group,
        category: filters.category,
        category_plus: filters.category_plus,
        attribute: this.attributeBindingData.baseAttribute.name,
        link_type: this.linkType,
        user: this.username,
      };

      const url = '/api/attribute-ap/remove-attributes-links';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      fetch(url, options)
        .then((response) => {
          if (response.ok) {
            this.createNotification('Attribute bind successfully removes', 'success');
            this.handleClose();
          } else {
            this.createNotification('An error ocured while deleting attrubutes bind', 'error');
          }
        });
    },

    handleClose() {
      this.$emit('bound-attributes-modal-close');
    },
  },
};
</script>

<style scoped>
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", IBM Plex Sans, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8203125rem;
  word-wrap: break-word;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 1px 1px 5px;
}

.modal-container {
  max-height: 100vh;
  min-width: 400px;
  max-width: 400px;
  padding: 0.5rem;
 }

.attributes-select-header {
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.attributes-selects {
  display: flex;
  flex-direction: column;
}

.attributes-select-container {
  display: flex;
  justify-content: space-between;
}

.attributes-select-title {
  font-size: 0.8rem;
  font-weight: 700;
}

.attributes-select-buttons {
  display: flex;
  justify-content: flex-end;
}

.btn-apply,
.btn-delete {
  margin-right: 5px;
}
</style>
